#center-container {
  padding-left: 10em;
  padding-right: 10em;
}

#center-consulter {
  padding-left: 10em;
  padding-right: 10em;
}

.centered-cell {
  text-align: center;
  vertical-align: middle;
}

.account-long-red-button {
  width: 45%;
}

.account-form {
  width: 50%;
}

@media (max-width: 768px) {
  #center-container {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media (max-width: 1024px) {
  #center-consulter {
    padding-left: 1em;
    padding-right: 1em;
  }

  #account-long-red-button {
    width: 75%;
  }

  .account-form {
    width: 97%;
  }
}

.bookpile-animation {
  animation-name: growFromBottom;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transform-origin: bottom;
}

@keyframes growFromBottom {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

div[class*="sub-stat"] {
  font-size: 0.96em;
}
